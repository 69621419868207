import { motion } from "framer-motion";
import { graphql, HeadFC } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React, { useState } from "react";
import Project from "../../components/Projects/Project";
import ServiceContentSection from "../../components/Services/ServiceContentSection";
import SectionTitle from "../../components/Titles/SectionTitle";
import ServiceTitle from "../../components/Titles/ServiceTitle";

import * as projectsStyles from "../projets/index.module.scss";

const ServicePage = ({ data }: any) => {
  const { service, projects } = data;
  const [imageModal, setImageModal] = useState<any>(undefined);

  const closeImageModal = () => {
    setImageModal(undefined);
  };

  return (
    <main>
      <ServiceTitle text={service.name} description={service.description} />

      {
        imageModal && (
          <motion.div className={projectsStyles.imageModal}>
            <motion.div layoutId={imageModal.id} className={projectsStyles.imageModalContainer}>
              <img
                src={imageModal.url}
                className={projectsStyles.image}
                alt="image"
              />
            </motion.div>
            <button onClick={closeImageModal} className={projectsStyles.closeButton}>
              <i className="fa fa-times"></i>
            </button>
          </motion.div>
        )
      }
      <section>

        {
          service.sections.map((section: any, index: number) => (
            <ServiceContentSection
              key={section._key}
              index={index + 1}
              title={section.title}
              content={section.content}
              image={section.image.asset} />
          ))
        }
      </section>

      {
        projects.edges.length > 0 && (
          <section style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <SectionTitle title="Projets" />
            <div className={projectsStyles.projectsContainer}>
              {
                projects?.edges?.map((project: any) => (
                  <Project
                    key={project.node._id}
                    name={project.node.title}
                    createdAt={new Date(project.node._createdAt)}
                    category={project.node.service.name}
                    categoryIcon={project.node.service.icon.name}
                    images={project.node.images}
                    layoutPrefix="service"
                    setImageModal={setImageModal}
                  />
                ))
              }
            </div>
          </section>
        )
      }
    </main>
  );
};

export const Head: HeadFC = ({ data }: any) => {
  const { general, service } = data;

  return (
    <>
      <title>{service.name} | {general.title}</title>
      <meta name="description" content={service.excerpt} />
    </>
  );
};

export const query = graphql`
  query($id: String!) {
    service: sanityService(id: { eq: $id }) {
      _id
      name
      excerpt (limit: 160)
      description {
        _key
        _type
        style
        list
        children {
          _key
          _type
          text
        }
      }
      sections {
        _key
        _type
        title
        content
        image {
          asset {
            url
            gatsbyImage(width: 800)
            altText
            id
          }
        }
      }
    }

    projects: allSanityProject(filter: {service: {id: {eq: $id}}}, sort: {fields: _createdAt, order: ASC}) {
      edges {
        node {
          _id
          _createdAt
          title
          service {
            _id
            name
            icon {
              name
            }
          }
          images {
            asset {
              id
              gatsbyImageData(width:1500)
              url
            }
          }
        }
      }
    }

    general: sanityGeneral {
      title
      description
      servicesDescription
    }
  }
`;

export default ServicePage;